import { mapperModuleMedia, mapperModuleNavigationLink } from '../../modules/mappers'
import type { MapperOptions, ModuleMedia } from '~/types'

/**
 * Block quote mapper
 */
export const mapperBlockQuote = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  // @TODO: standardize how text is passing

  return {
    id: data.sys?.id || '',
    quote: data.quote || '',
    text: data.quote || '',
    author: data.author || '',
    stars: data.stars || null,
    tag: data.tag || '',
    role: data.role || '',
    logo: (mapperModuleMedia(data.logo, options.imageOptions) as ModuleMedia)?.original,
    image: mapperModuleMedia(data.image, options.imageOptions),
    link: mapperModuleNavigationLink(data.link, options.getUrl)
  }
}
