export const useI18nPageInfo = () => {
  const route = useRoute()

  const initSEO = async ({ sysId = 'none' }) => {
    let routeParams = {}
    try {
      // Note: we cannot pass the full `route` object as param to api endpoint because it breaks when running on client side (circular JSON exception)
      routeParams = await $fetch(`/api/v3/core_routes/i18n_params/${sysId}`, {
        method: 'POST',
        body: {
          route: {
            path: route.path,
            params: route.params
          }
        }
      })
    } catch (e) {
      console.log(
        `[initSEO] [ERROR] Cannot retrive i18n route params for page ${route.path} [sysId: ${sysId}][ERROR=${e}]`
      )
    }
    return routeParams
  }

  return {
    initSEO
  }
}
