import { mapperModuleMetric, mapperModuleNavigationLink } from '../../modules/mappers'
import type { MapperOptions } from '@/types'

/**
 * Block Text Metrics mapper
 */
export const mapperBlockTextMetrics = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    contentType: data.contentType || '',
    text: data.blockTextMetricsText || null,
    metricsTitle: data.metricsTitle || '',
    metricsItems: data.metricsItemsCollection?.items?.map((item: any) => mapperModuleMetric(item)) || [],
    link: mapperModuleNavigationLink(data.link, options.getUrl)
  }
}
