/**
 * Global data currency mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */
export default (data?: any) => {
  if (!data) {
    return null
  } return {
    id: data?.sys?.id || '',
    name: data?.name || '',
    isoCode: data?.isoCode || ''
  }
}
