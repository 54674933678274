import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockRelatedContent } from '../../../core/data-layer/blocks/fragments'

import { fragmentBlockCampaignsThankYouContent } from '../blocks/fragments'
import { fragmentGlobalDataTheme } from '../../../core/data-layer/global-data/fragments'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentGlobalDataTheme}
  ${fragmentBlockRelatedContent}
  ${fragmentBlockCampaignsThankYouContent}
  fragment ContentKnowledgeHubCampaignsGuideFragment on ContentKnowledgeHubCampaignsGuide {
    sys {
      id
    }
    headerLink {
      ...ModuleNavigationLinkFragment
    }
    secondaryHeaderLink {
      ...ModuleNavigationLinkFragment
    }
    headerEyebrow
    headerSubtitle {
      json
    }
    formId
    showFormInHeader
    formAnchorLabel
    formTitle
    formDescription {
      json
    }
    formSubmitLabel
    formSuccessRedirectPage {
      ...ModuleNavigationLinkFragment
    }
    hasAnimatedHero
    headerImage {
      ... on ModuleMedia {
        contentType: __typename
        ...ModuleMediaFragment
      }
      ... on ModuleInternalVideo {
        contentType: __typename
        ...ModuleInternalVideoFragment
      }
    }
    fpZoom
    desktopHeaderImage {
      ... on ModuleMedia {
        contentType: __typename
        ...ModuleMediaFragment
      }
      ... on ModuleInternalVideo {
        contentType: __typename
        ...ModuleInternalVideoFragment
      }
    }
    fpZoomDesktop
    relatedContent {
      ...BlockRelatedContentFragment
    }
    thankYouContent {
      ...BlockCampaignsThankYouContentFragment
    }
    heroTheme {
      ...GlobalDataThemeFragment
    }
  }
`
