import type { AdyenRoute } from '../types'
import { useRoutesUtils } from './useRoutesUtils'

type UrlData = {
  internalLink?: any
  externalLink?: string
}

/**
 * This needs to be typed this way because the param won't be overridden by `setI18nParams` function
 * There's an open issue on Nuxt i18n repository
 * @see https://github.com/nuxt-modules/i18n/issues/2782
 */
type RouteParams = Record<string, string | null>
export type LocalizedRouteParams = Record<string, RouteParams>

export const useRoutes = (routes: AdyenRoute[], locale: Ref<string>) => {
  const { sanitizeRoutePath } = useRoutesUtils()

  if (typeof locale === 'string') {
    console.error('///////// LOCALE shoud be a Ref. Use `wrapLocale` from RouteUtils.')
  }

  /**
   * Get the route data based in the entry id and locale
   */
  const getRouteByEntry = (entryId: string) => {
    return routes.find(({ payload }) => payload?.id === entryId && payload?.locale === locale.value)
  }

  // const bindRoutesToGetInternalUrl = (routes: AdyenRoute[], locale: string) => {
  //   return (entryId: string) => {
  //     return getInternalUrl(entryId, routes, locale)
  //   }
  // }

  /**
   * Get internal URL to be used in link components
   */
  const getInternalUrl: Function = (entryId: string): string => {
    return getRouteByEntry(entryId)?.route || ''
  }

  // const bindRoutesToGetUrl = (routes: AdyenRoute[], locale: string) => {
  //   return (urlData: UrlData) => {
  //     return getUrl(urlData, routes, locale)
  //   }
  // }

  const getUrl: Function = (urlData: UrlData) => {
    if (!urlData) {
      return { url: '', route: null, lazyUrlEndpoint: null }
    }

    const { internalLink, externalLink } = urlData
    // prioritize internal urls over externals
    if (internalLink?.sys?.id) {
      const route = getRouteByEntry(internalLink?.sys?.id)

      return {
        lazyUrlEndpoint: route?.route
          ? null
          : '/api/v3/core_routes/entry/' + internalLink.sys.id + '?locale=' + locale.value,
        url: route?.route || '',
        route
      }
    }

    if (externalLink) {
      return { url: externalLink, route: null, lazyUrlEndpoint: null }
    }

    return { url: '', route: null, lazyUrlEndpoint: null }
  }

  const getRouteParams = (
    entryId: string,
    isHome: boolean,
    routeParams: any,
    availableLocales: string[]
  ): LocalizedRouteParams => {
    const paramsNames = Object.keys(routeParams || {})
    // Current child param name
    const currentRouteParamName = paramsNames.pop() ?? ''

    // For every other param ('parents'), we retrieve the id of the entry (does not matter the locale)
    const paramsWithEntryId: [string, string][] = paramsNames.map((paramName) => {
      const paramValue = routeParams[paramName]
      const paramEntryId = routes.find((r) => r.payload.slug === paramValue)?.payload.id
      return [paramName, paramEntryId]
    })

    const localizedRouteParams = routes
      .filter(({ payload }) => payload.id === entryId)
      .reduce((acc, route) => {
        if (route?.payload?.slug) {
          const locale: string = route.payload.locale
          acc[locale] = {}
          // We actually need to give the proper route param name ex: 'country', 'region', not always 'slug'
          acc[locale][currentRouteParamName] = isHome ? '/' : route.payload.slug

          // If there were parent entries, we also need to replace them
          paramsWithEntryId.forEach(([paramName, paramEntryId]) => {
            if (!acc[locale]) {
              return
            }
            const slug = routes.find((r) => r.payload.id === paramEntryId && r.payload.locale === locale)?.payload?.slug
            acc[locale][paramName] = slug
          })
        }
        return acc
      }, {} as LocalizedRouteParams)

    const params = Object.keys(routeParams) ?? {}
    const normalizedRouteParams = availableLocales.reduce((acc, curr) => {
      const param = localizedRouteParams[curr]
      if (param) {
        acc[curr] = param
        return acc
      }

      // every locale that doesn't have a translated version of the current route
      // needs to set its params path to null
      // in order to avoid rendering `link` tags with `alternate` locales that don't exist
      if (params.length > 0) {
        const normParams: RouteParams = {}
        params.forEach((key) => {
          normParams[key] = null
        })
        acc[curr] = normParams
      }

      return acc
    }, {} as LocalizedRouteParams)

    return normalizedRouteParams
  }

  const getDefaultLocaleRoute = (path: string, defaultLocale: string) => {
    // sanitized route path without tailing slash
    const routePath = sanitizeRoutePath(path)

    // find route by path
    const route = routes.find(({ route }) => route === routePath)

    // if route correspond to default locale return it
    if (route?.payload?.locale === defaultLocale) {
      return route
    }

    // if not, search route using entry id and locale and return it
    return routes.find(({ payload }) => payload?.id === route?.payload?.id && payload?.locale === defaultLocale) || {}
  }

  const getRouteByPath = (path: string) => {
    // sanitized route path without tailing slash
    const routePath = sanitizeRoutePath(path)

    return routes.find(({ route }) => route === routePath) || null
  }

  const getRoute = (slug: string) =>
    routes.find(({ payload }) => payload.slug === slug && payload.locale === locale.value) || null

  const getRouteByContentType = (slug: string, contentType: string) =>
    routes.find(
      ({ payload }) => payload.contentType === contentType && payload.slug === slug && payload.locale === locale.value
    ) || null

  const routeExists = (path: string) => {
    const routePath = sanitizeRoutePath(path)
    return routes.some(({ route }) => route === routePath)
  }

  return {
    getRouteByEntry,
    getRouteParams,
    getDefaultLocaleRoute,
    getRouteByPath,
    getRoute,
    getRouteByContentType,
    routeExists,
    getInternalUrl,
    getUrl
  }
}
