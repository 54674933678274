import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'
import { fragmentGlobalDataTheme } from '../../global-data/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentGlobalDataTheme}
  fragment BlockSimpleQuoteFragment on BlockSimpleQuote {
    sys {
      id
    }
    logo {
      ...ModuleMediaFragment
    }
    quote
    author
    authorDetails
    theme {
      ...GlobalDataThemeFragment
    }
  }
`
