import { mapperModuleMedia, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import { mapperBlockLogoBar, mapperBlockMediaCardGroup, mapperBlockQuoteGroup } from '../../../core/data-layer/blocks/mappers'
import imageSizes from '../../../core/constants/imageSizes'
import type { MapperOptions } from '../../../core/types'

// IF data.testimonials contains a quotesCollection, use the mapperBlockQuoteGroup
const testimonialsMapperForData = (data: any) => {
  return data.testimonials?.quotesCollection ? mapperBlockQuoteGroup : mapperBlockMediaCardGroup
}

/**
 * Page careers vacancies detail mapper
 * normalizes data to be used in pages
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const headingTestimonialsOptions = {
    lazy: true,
    sizes: { small: { width: 327, height: 184, aspectRatio: '16/9' }, medium: { width: 312, height: 176, aspectRatio: '16/9' }, large: { width: 576, height: 324, aspectRatio: '16/9' } },
    resize: true
  }

  const testimonialsOptions = {
    lazy: true,
    sizes: { small: { width: 272, height: 153, aspectRatio: '16/9' }, medium: { width: 368, height: 207, aspectRatio: '16/9' }, large: { width: 368, height: 207, aspectRatio: '16/9' } },
    resize: true
  }

  return {
    description: data.description || '',
    careerType: data.careerType || '',
    headingTitle: data.headingTitle || '',
    headingDescription: data.headingDescription || '',
    headingMedia: mapperModuleMedia(data.headingMedia, { resize: true, sizes: imageSizes.columns12_5269 }),
    headingVideo: mapperModuleVideo(data.headingVideo, { resize: true, sizes: imageSizes.columns12_5269 }),
    headingTestimonials: mapperBlockMediaCardGroup(data.headingTestimonials, { ...options, imageOptions: headingTestimonialsOptions }),
    testimonials: testimonialsMapperForData(data)(data.testimonials, { ...options, imageOptions: testimonialsOptions }),
    logoBar: mapperBlockLogoBar(data.logoBar, options)
  }
}
