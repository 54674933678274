import { gql } from 'graphql-tag'
import { fragmentBlockCampaignsMedia, fragmentBlockKnowledgeHubChapter } from '../../fragments'
import { fragmentBlockMetrics, fragmentBlockContent, fragmentBlockCallToAction, fragmentBlockRelatedContent } from '../../../../core/data-layer/blocks/fragments'
import { fragmentModuleMedia, fragmentModuleVideo, fragmentModuleInternalVideo } from '../../../../core/data-layer/modules/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleVideo}
    ${fragmentModuleInternalVideo}
    ${fragmentBlockCampaignsMedia}
    ${fragmentBlockMetrics}
    ${fragmentBlockKnowledgeHubChapter}
    ${fragmentBlockContent}
    ${fragmentBlockRelatedContent}
    ${fragmentBlockCallToAction}
    fragment ContentKnowledgeHubCaseStudyFragment on ContentKnowledgeHubCaseStudy
    {
        sys {
            id
        }
        headerMedia {
            ...on ModuleMedia {
                contentType: __typename
                ...ModuleMediaFragment
            }
            ...on ModuleVideo {
                contentType: __typename
                ...ModuleVideoFragment
            }
            ... on ModuleInternalVideo {
                contentType: __typename
                ...ModuleInternalVideoFragment
            }
        }
        eyebrow
        pageTitle
        headerIntroText {
            json
        }
        metrics {
            contentType: __typename
            ...BlockMetricsFragment
        }
        videoSection {
            contentType: __typename
            ...BlockCampaignsMediaFragment
        }
        chaptersIntroText {
            json
        }
        chaptersCollection (limit: 12){
            items {
                contentType: __typename
                ...BlockKnowledgeHubChapterFragment
            }
        }
        summariesCollection (limit: 3) {
            items {
                contentType: __typename
                ...BlockContentFragment
            }
        }
        relatedContent {
            contentType: __typename
            ...BlockRelatedContentFragment
        }
        callToAction {
            contentType: __typename
            ...BlockCallToActionFragment
        }
    }
`
