import { mapperModuleNavigationLink } from '../../modules/mappers'

/**
 * Block form mapper
 */
export const mapperBlockForm = (data: any, getUrl: Function) => {
  if (!data) {
    return null
  }
  return {
    title: data.title || '',
    text: data.text || null,
    formTitle: data.formTitle || '',
    formId: data.formId || '',
    link: mapperModuleNavigationLink(data.link, getUrl),
    submitLabel: data.submitLabel || '',
    advancedRedirectConfig: data.advancedRedirectConfig || {}
  }
}
