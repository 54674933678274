import mapperGlobalDataCurrency from '../global-data-currency/mapper-global-data-currency'
import mapperIcon from '../../utils/mappers/mapper-icon'

/**
 * Global Data Country mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */

export default (data: any) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    contentType: data.contentType || '',
    name: data.name || '',
    icon: mapperIcon(data.icon),
    isGlobal: data.internalName === 'GLOBAL',
    region: data.region || null, // @TODO add global data region mapper
    processingFeeAmount: data.processingFeeAmount || '',
    processingFeeCurrency: mapperGlobalDataCurrency(data.processingFeeCurrency)
  }
}
