import imgModule from '../../constants/imageSizes'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const BlockIndexChapter = {
  contentType: 'BlockIndexChapter',
  fields: [
    {
      id: 'Title',
      name: 'Title',
      type: 'Symbol'
    },
    {
      id: 'navigationTitle',
      name: 'Navigation Title',
      type: 'Symbol'
    },
    {
      id: 'contentGroups',
      name: 'Content Groups',
      type: 'Array',
      subType: 'BlockIndexContentGroup',
      validations: [{ size: { min: 0, max: 8 } }]
    }
  ]
}

const BlockIndexContentGroup = {
  contentType: 'BlockIndexContentGroup',
  fields: [
    {
      id: 'contentBlocks',
      name: 'Content Blocks',
      type: 'Array',
      subType: ['ModuleMedia', 'BlockMetrics', 'BlockContent', 'BlockSimpleQuote', 'ModuleVideo', 'ModuleInternalVideo', 'BlockIndexHighlight'],
      validations: [{ size: { min: 0, max: 8 } }]
    }
  ]
}

const BlockIndexHighlight = {
  contentType: 'BlockIndexHighlight',
  fields: [
    {
      id: 'title',
      name: 'Title',
      type: 'Symbol'
    },
    {
      id: 'text',
      name: 'Text',
      type: 'RichText'
    },
    {
      id: 'isFullScreen',
      name: 'Display Full screen',
      type: 'Boolean'
    },
    {
      id: 'link',
      name: 'Link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'theme',
      name: 'Theme',
      type: 'GlobalDataTheme'
    }
  ]
}

const PageIndex = {
  contentType: 'PageIndex',
  fields: [
    {
      id: 'reports',
      name: 'Our Reports',
      type: 'BlockReportsList',
      imageOptions: { resize: true, sizes: imageSizes.index_reports }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockSimpleQuote'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_21 }
    },
    {
      id: 'heroEyebrow',
      name: 'Eyebrow',
      type: 'Text',
      localized: true
    }
  ]
}

const PageIndexIndustryGated = {
  contentType: 'PageIndexIndustryGated',
  fields: [
    {
      id: 'backLink',
      name: 'Back Link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'intro',
      name: 'Intro',
      type: 'BlockTextMetrics'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns10_21 }
    },
    {
      id: 'contentBlock1',
      name: 'Content Block 1',
      type: 'BlockContent'
    },
    {
      id: 'metricsBlock',
      name: 'Metrics Block',
      type: 'BlockMetrics'
    },
    {
      id: 'form',
      name: 'Form',
      type: 'BlockForm'
    },
    {
      id: 'heroEyebrow',
      name: 'Eyebrow',
      type: 'Text',
      localized: true
    }
  ]
}

const PageIndexIndustryUngated = {
  contentType: 'PageIndexIndustryUngated',
  fields: [
    {
      id: 'backLink',
      name: 'Back Link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'heroImage',
      name: 'Hero Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns4_34 }
    },
    {
      id: 'intro',
      name: 'Intro',
      type: 'BlockTextMetrics'
    },
    {
      id: 'contentBlock1',
      name: 'Content Block 1',
      type: 'BlockContent'
    },
    {
      id: 'metricsBlock',
      name: 'Metrics Block',
      type: 'BlockMetrics'
    },
    {
      id: 'reports',
      name: 'Sub Industry Reports',
      type: 'BlockReportsList'
    },
    {
      id: 'blockIndexChapter',
      name: 'Content Groups',
      type: 'Array',
      subType: 'BlockIndexChapter',
      validations: [{ size: { min: 0, max: 8 } }],
      lazy: true
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'heroEyebrow',
      name: 'Eyebrow',
      type: 'Text',
      localized: true
    },
    {
      id: 'navigationButton',
      name: 'Navigation Button',
      type: ['ModuleMedia', 'ModuleNavigationLink'],
      localized: true
    },
    {
      id: 'form',
      name: 'Form (experiment)',
      type: 'BlockForm'
    }
  ]
}

const PageIndexSubIndustry = {
  contentType: 'PageIndexSubIndustry',
  fields: [
    {
      id: 'parent',
      name: 'Index Industry parent page',
      type: 'PageIndexIndustryGated',
      required: true
    },
    {
      id: 'backLink',
      name: 'Back Link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'media',
      name: 'Media Image or Video',
      type: ['ModuleMedia', 'ModuleVideo', 'ModuleInternalVideo']
      // @TODO define image sizes: 16:9 - 12 cols
      // imageOptions: { resize: true, sizes: imageSizes.columns3_34 }
    },
    {
      id: 'intro',
      name: 'Intro',
      type: 'Text'
    },
    {
      id: 'blockIndexChapter',
      name: 'Content Groups',
      type: 'Array',
      subType: 'BlockIndexChapter',
      validations: [{ size: { min: 0, max: 8 } }],
      lazy: true
    },
    {
      id: 'logobar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'heroEyebrow',
      name: 'Eyebrow',
      type: 'Text',
      localized: true
    },
    {
      id: 'form',
      name: 'Form (experiment)',
      type: 'BlockForm'
    }
  ]
}

const PageIndexPrevious = {
  contentType: 'PageIndexPrevious',
  fields: [
    {
      id: 'linksList',
      name: 'Links List',
      type: 'Array',
      subType: 'BlockLinksList',
      validations: [{ size: { min: 1, max: 20 } }]
    },
    {
      id: 'backLink',
      name: 'Back Link',
      type: 'ModuleNavigationLink'
    }
  ]
}

const BlockLinksList = {
  contentType: 'BlockLinksList',
  fields: [
    {
      id: 'title',
      name: 'Title',
      type: 'Symbol',
      required: true,
      localized: true
    },
    {
      id: 'links',
      name: 'Links',
      type: 'Array',
      subType: 'ModuleNavigationLink',
      validations: [{ size: { min: 1, max: 20 } }],
      required: true,
      localized: true
    }
  ]
}

export default {
  BlockIndexChapter,
  BlockIndexContentGroup,
  BlockIndexHighlight,
  BlockLinksList,

  PageIndex,
  PageIndexIndustryGated,
  PageIndexIndustryUngated,
  PageIndexSubIndustry,
  PageIndexPrevious
}
