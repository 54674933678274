import imageSizes from '../../constants/imageSizes'

const PageCharities = {
  contentType: 'PageCharities',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns5_34 }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true
    },
    {
      id: 'quoteGroup1',
      name: 'Quote Group 1',
      type: 'BlockQuoteGroup'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_34, lazy: true }
    },
    {
      id: 'quoteGroup2',
      name: 'Quote Group 2',
      type: 'BlockQuoteGroup'
    },
    {
      id: 'row1Title',
      name: 'Row 1 - Title ',
      type: 'Text'
    },
    {
      id: 'row2Title',
      name: 'Row 2 - Title ',
      type: 'Text'
    },
    {
      id: 'row1Description',
      name: 'Row 1 - Description ',
      type: 'Text'
    },
    {
      id: 'row2Description',
      name: 'Row 2 - Description ',
      type: 'Text'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'cta1',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageCharitiesLibrary = {
  contentType: 'PageCharitiesLibrary',
  fields: [
    {
      id: 'title',
      name: 'Charity hub title',
      type: 'Symbol'
    },
    {
      id: 'heroDescription',
      name: 'Description',
      type: 'Text'
    },
    {
      id: 'charityHighlight',
      name: 'Charity Highlight',
      type: 'BlockHighlight'
    },
    {
      id: 'cta',
      name: 'Call to action ',
      type: 'BlockCallToAction'
    }
  ]
}

const PageCharitiesDetail = {
  contentType: 'PageCharitiesDetail',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'title',
      name: 'Charity title',
      type: 'Symbol'
    },
    {
      id: 'charityImage',
      name: 'Charity Image',
      type: 'ModuleMedia',
      imageOptions: [
        // hub card
        { resize: true, sizes: imageSizes.columns4_169, recolor: '#ffffff' },
        // detail hero
        { resize: true, sizes: imageSizes.columns6_43, recolor: '#ffffff' }
      ],
      includeInRelated: true
    },
    {
      id: 'charityName',
      name: 'Charity name',
      type: 'Symbol',
      includeInRelated: true
    },
    {
      id: 'charityDescription',
      name: 'Charity description',
      type: 'Text'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'image',
      name: 'image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns10_169, lazy: true }
    },
    {
      id: 'charitiesSdg',
      name: 'SDGs',
      type: 'Array',
      subType: 'ModuleCharitiesSdg'
    },
    {
      id: 'donationDescription',
      name: 'Charity donation description',
      type: 'Text'
    },
    {
      id: 'donationInAction',
      name: 'Your donation in Action',
      type: 'Text'
    },
    {
      id: 'cta',
      name: 'Call to action ',
      type: 'BlockCallToAction'
    },
    {
      id: 'logobar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'country',
      name: 'Country',
      type: 'Array',
      subType: 'GlobalDataCountry'
    },
    {
      id: 'charityShortDescription',
      name: 'Charity Short Description',
      type: 'Text'
    },
    {
      id: 'externalLink',
      name: 'Charity External Link',
      type: 'Symbol',
      validations: [
        {
          regexp: {
            pattern: '^(ftp|http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$', // copied from Contentful URL validation
            flags: null
          }
        }
      ]
    }
  ]
}

const CharityLabels = {
  contentType: 'CharitiesLabels',
  fields: [
    {
      id: 'region',
      name: 'Region',
      type: 'Symbol'
    },
    {
      id: 'country',
      name: 'Country',
      type: 'Symbol'
    },
    {
      id: 'charitiesSdg',
      name: 'SDGs',
      type: 'Symbol'
    },
    {
      id: 'noResult',
      name: 'No Result',
      type: 'Symbol'
    },
    {
      id: 'found',
      name: 'Charities found',
      type: 'Symbol'
    },
    {
      id: 'donationAction',
      name: 'Your donation in action',
      type: 'Symbol'
    },
    {
      id: 'otherCharities',
      name: 'Explore other charities',
      type: 'Symbol'
    },
    {
      id: 'charityHub',
      name: 'Charity hub',
      type: 'Symbol'
    },
    {
      id: 'sdg',
      name: 'SDG',
      type: 'Symbol'
    }
  ]
}

const ModuleCharitySDG = {
  contentType: 'ModuleCharitiesSdg',
  fields: [
    {
      id: 'name',
      name: 'SDG name',
      type: 'Symbol'
    },
    {
      id: 'icon',
      name: 'SDG icon name',
      type: 'Symbol'
    }
  ]
}

export default {
  PageCharities,
  PageCharitiesLibrary,
  PageCharitiesDetail,
  CharityLabels,
  ModuleCharitySDG
}
