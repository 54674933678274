import { gql } from 'graphql-tag'

const moduleChapterFields = `
  sys {
    id
  }
  title
  text {
    json
  }
`

export default gql`
  fragment ModuleChapterFragment on ModuleChapter {
    ${moduleChapterFields}
    subchaptersCollection(limit: 15) {
      items {
        ... on ModuleChapter {
          ${moduleChapterFields}
          subchaptersCollection(limit: 5) {
            items {
              ... on ModuleChapter {
                ${moduleChapterFields}
                subchaptersCollection(limit: 5) {
                  items {
                    ${moduleChapterFields}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
