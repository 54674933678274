import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../modules/fragments'

export default gql`
  ${fragmentModuleNavigationLink}
  fragment BlockLinksListFragment on BlockLinksList {
    sys {
      id
    }
    title
    linksCollection {
      items {
        ...ModuleNavigationLinkFragment
      }
    }
  }
`
