import { gql } from 'graphql-tag'
import { fragmentModuleMetric, fragmentModuleNavigationLink } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMetric}
  ${fragmentModuleNavigationLink}
  fragment BlockTextMetricsFragment on BlockTextMetrics {
    sys {
      id
    }
    contentType: __typename
    blockTextMetricsText: text {
      json
    }
    metricsTitle
    metricsItemsCollection {
      items {
        ...ModuleMetricFragment
      }
    }
    link {
      ...ModuleNavigationLinkFragment
    }
  }
`
