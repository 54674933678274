export const useTrackingUtils = () => {
  /**
   * Push event data to dataLayer
   * @param eventData
   */
  const trackEvent = (eventData) => {
    if (!eventData) {
      return
    }
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(eventData)
    } else {
      /* eslint-disable-next-line no-console */
      console.warn('dataLayer is not available.')
    }
  }

  /**
   * Options for filter tracking
   */
  const filterTrackingOptions = {
    componentType: {
      knowledgeHub: 'filter_knowledgehub',
      pricing: 'filter_pricing',
      paymentMethods: 'filter_paymentmethods',
      home: 'filter_home',
      terminals: 'filter_terminals'
    },
    event: {
      interacted: 'filter_interacted'
    },
    action: {
      interacted: 'interacted'
    },
    filterType: {
      dropdown: 'dropdown',
      bar: 'bar',
      toggle: 'toggle'
    }
  }

  /**
   * Mapper for filter tracking
   * @returns { event: string, {filter: {component_type: string, selection: string, name: string, action: string, type: string } } }
   * @param filterData
   */
  const filterTrackingDataMapper = (filterData: any) => {
    if (!filterData) {
      // eslint-disable-next-line no-console
      console.warn('filterTrackingDataMapper called with invalid args')
      return
    }

    return {
      event: filterTrackingOptions.event.interacted,
      filter: {
        component_type: filterData.componentType,
        action: filterTrackingOptions.action.interacted,
        name: filterData.filterName,
        type: filterData.filterType,
        selection: filterData.filterItem
      }
    }
  }

  /**
   * Get a label even if the value passed is an `id`
   *
   * @param value {String} - value coming from filter
   * @param filterOptions {Array} - options available in the filter, that contain the ids and labels
   * @param isValueId {Boolean} - if the value passed is an id
   * @returns {string} - Label, if no label is found then it returns the selected value
   */
  const getFilterItemLabel = (value = '', filterOptions = [], isValueId = false) => {
    return isValueId ? filterOptions.find((item) => item?.id === value)?.text || value : value
  }

  /**
   * Get the mapped object for filter tracking
   *
   * @returns { event: string, {filter: {component_type: string, selection: string, name: string, action: string, type: string } } }
   * @param filterData
   */
  const filterItemTrackingData = (
    filterData = {
      filterName: '',
      filterValue: '',
      isValueId: false,
      componentType: '',
      filterType: '',
      filterOptions: [],
      previousFilterState: []
    }
  ) => {
    if (!filterData) {
      // eslint-disable-next-line no-console
      console.warn('filterItemTrackingData called with invalid args')
      return
    }

    let value = filterData.filterValue

    // When a filter is cleared, the tracked selection is an empty string
    if (Array.isArray(value) && value.length === 0) {
      value = ''
    }

    if (Array.isArray(value) && value.length > 0) {
      const isItemAdded = value.length > (filterData.previousFilterState?.length || 0)

      if (isItemAdded) {
        value = value[value.length - 1]
      } else {
        value = filterData.previousFilterState.find((x) => !value.includes(x))
      }
    }

    const label = getFilterItemLabel(value, filterData.filterOptions, filterData.isValueId)

    return filterTrackingDataMapper({
      componentType: filterData.componentType,
      filterName: filterData.filterName,
      filterType: filterData.filterType,
      filterItem: label
    })
  }

  return {
    trackEvent,
    filterTrackingOptions,
    filterTrackingDataMapper,
    getFilterItemLabel,
    filterItemTrackingData
  }
}
