import type { PageMeta } from '~/types'
import { useTrackingUtils } from '../utils/useTrackingUtils'

export const usePageMeta = () => {
  const siteUrl = useRuntimeConfig().public.siteUrl
  const { ogImage } = useGlobalData()
  const { wrapLocale } = useRoutesUtils()
  const { trackEvent } = useTrackingUtils()
  const store = useRoutesStore()
  const route = useRoute()

  const buildPageMeta = (seoData: any): PageMeta | null => {
    if (!seoData) {
      return null
    }
    const pageMeta = {
      title: seoData.title + ' - Adyen',
      image: seoData.ogImage || ogImage.value,
      description: seoData.description,
      robots: seoData.robots,
      url: siteUrl + route.path
    }
    return pageMeta
  }

  /**
   * trackPage,
   * handles all tracking events on page load
   */
  async function trackPage(locale: string) {
    await store.retrieveRoutes()
    const { getDefaultLocaleRoute } = useRoutes(store.routes, wrapLocale(locale))

    // Skip tracking if not running in the client

    const defaultLocaleRoute = getDefaultLocaleRoute(route?.path, 'en')

    const [lang, region] = locale?.split('_') || []

    const eventData = {
      event: 'virtual_page',
      page: {
        group: defaultLocaleRoute?.payload?.category,
        type: defaultLocaleRoute?.payload?.subcategory,
        path: defaultLocaleRoute?.route,
        locale_path: route?.path,
        full_url: window?.location.href
      },
      site: {
        lang,
        region
      }
    }

    trackEvent(eventData)
  }

  return {
    trackPage,
    buildPageMeta
  }
}
