import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleNavigationLink } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleNavigationLink}
  fragment BlockLogoBarFragment on BlockLogoBar {
    contentType: __typename
    sys {
      id
    }
    logosCollection (limit: 18) {
      items {
        ...ModuleMediaFragment
      }
    }
    title
    logosDescription: description {
      json
    }
    link {
      ...ModuleNavigationLinkFragment
    }
    footnote
  }
`
